import heic2any from 'heic2any'

async function getHeicToJpeg(file) {
  if (file.type !== 'image/heic' && file.type !== 'image/heif') {
    return file
  }
  const JpegBlob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
  })

  const name = file.name.split('.')[0] + '.jpeg'
  const newFile = new File([JpegBlob], name)

  return newFile
}

async function convertToWebP(file) {
  const options = {
    maxWidth: 2048,
    maxHeight: 2048,
  }
  try {
    const result = await convertSingleFileToWebP(file, options)
    return result
  } catch (error) {
    console.error(`Error converting ${file.name}:`, error)
    showError(`이미지 변환 중 오류가 발생했습니다: ${file.name}`)
  }
}

async function convertSingleFileToWebP(file, options) {
  const newFile = await getHeicToJpeg(file)
  const dataUrl = await readFileAsDataURL(newFile)
  const img = await loadImage(dataUrl)
  const resizedImage = resizeImage(img, options.maxWidth, options.maxHeight)
  const blob = await createWebPBlob(resizedImage)
  const webpUrl = URL.createObjectURL(blob)
  return { webpUrl, fileName: file.name, file: blob }
}

function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (e) => resolve(e.target.result)
    reader.onerror = reject
    reader.readAsDataURL(file)
  })
}

function resizeImage(img, maxWidth, maxHeight) {
  const canvas = document.createElement('canvas')
  let width = img.width
  let height = img.height

  if (maxWidth && width > maxWidth) {
    height = (maxWidth / width) * height
    width = maxWidth
  }

  if (maxHeight && height > maxHeight) {
    width = (maxHeight / height) * width
    height = maxHeight
  }

  canvas.width = width
  canvas.height = height

  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height)
  }

  return canvas
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = reject
    img.src = src
  })
}

function createWebPBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob)
      } else {
        reject(new Error('Failed to create blob'))
      }
    }, 'image/webp')
  })
}

function showError(message) {
  const errorElement = document.getElementById('error-message')
  if (errorElement) {
    errorElement.textContent = message
  } else {
    console.error('Error element not found:', message)
  }
}

function clearError() {
  const errorElement = document.getElementById('error-message')
  if (errorElement) {
    errorElement.textContent = ''
  }
}

function handleFileSelection(event) {
  const fileInput = event.target
  const fileNameDisplay = document.querySelector('.file-name')
  const submitButton = document.getElementById('submitButton')

  clearError()

  if (fileInput.files.length > 0) {
    const file = fileInput.files[0]
    if (fileNameDisplay) fileNameDisplay.value = file.name
    if (submitButton) submitButton.disabled = false
  } else {
    if (fileNameDisplay) fileNameDisplay.value = ''
    if (submitButton) submitButton.disabled = false
  }
}

function showSpinner() {
  const spinner = document.getElementById('spinner-overlay')
  if (spinner) {
    spinner.classList.remove('none-spin')
  }
}

function hideSpinner() {
  const spinner = document.getElementById('spinner-overlay')
  if (spinner) {
    spinner.classList.add('none-spin')
  }
}

function initializeTagInput() {
  const tagContainer = document.querySelector('.tag-container')
  const tagInput = document.getElementById('tagInput')
  let isComposing = false
  let lastChar = ''

  tagInput.addEventListener('input', function (e) {
    const currentValue = e.target.value
    const lastCharEntered = currentValue[currentValue.length - 1]

    if (!isComposing && lastCharEntered === ' ' && lastChar !== ' ') {
      e.preventDefault()
      addTag()
    }

    lastChar = lastCharEntered
  })

  tagInput.addEventListener('keydown', function (e) {
    if (!isComposing) {
      if (e.key === 'Enter') {
        e.preventDefault()
        addTag()
      } else if (e.key === 'Backspace' && tagInput.value === '') {
        e.preventDefault()
        removeLastTag()
      }
    }
  })

  tagInput.addEventListener('compositionstart', () => {
    isComposing = true
  })

  tagInput.addEventListener('compositionend', (e) => {
    isComposing = false
    if (e.data.endsWith(' ')) {
      addTag()
    }
  })

  function addTag() {
    if (isTwo()) {
      return
    }
    const value = tagInput.value.trim()
    if (value && !tagExists(value)) {
      const tag = document.createElement('span')
      tag.classList.add('tag')
      tag.textContent = value

      const closeBtn = document.createElement('span')
      closeBtn.classList.add('tag-close')
      closeBtn.textContent = '×'
      closeBtn.addEventListener('click', function () {
        tag.remove()
      })

      tag.appendChild(closeBtn)
      tagContainer.insertBefore(tag, tagInput)
      tagInput.value = ''
      lastChar = ''
    }
  }

  function isTwo() {
    const tags = tagContainer.querySelectorAll('.tag')
    return !(tags.length < 2)
  }

  function removeLastTag() {
    const tags = tagContainer.querySelectorAll('.tag')
    if (tags.length > 0) {
      tags[tags.length - 1].remove()
    }
  }

  function tagExists(value) {
    const existingTags = tagContainer.querySelectorAll('.tag')
    return Array.from(existingTags).some(
      (tag) => tag.textContent.slice(0, -1) === value,
    )
  }

  // 포커스가 태그 입력창을 벗어날 때 태그 추가
  tagInput.addEventListener('blur', addTag)
}

async function handleSubmit() {
  clearError()

  const formData = new FormData()

  // 각 필드의 값을 가져와서 FormData에 추가
  formData.append('name', document.getElementById('name').value)
  formData.append('clubName', document.getElementById('clubName').value)
  formData.append('snsInfo', document.getElementById('snsInfo').value)

  // 전문분야 태그 추가
  const tags = document.querySelectorAll('.tag')
  tags.forEach((tag, index) => {
    formData.append(`specialty[${index}]`, tag.textContent.slice(0, -1))
  })

  // Validation
  if (!formData.get('name')) {
    showError('정비사님 성함을 입력해주세요.')
    return
  }

  if (tags.length === 0) {
    showError('전문분야를 하나 이상 입력해주세요.')
    return
  }

  // 파일 추가 및 변환
  const fileInput = document.getElementById('cover')
  if (fileInput.files.length > 0) {
    showSpinner()
    const originalFile = fileInput.files[0]
    const { file } = await convertToWebP(originalFile)
    formData.append('photo', file, convertToWebpExtension(originalFile.name))
  } else {
    showError('대표사진을 선택해주세요.')
    return
  }

  // AJAX를 사용하여 서버로 데이터 전송
  fetch('/sign-up/expert', {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      hideSpinner()
      if (data.success) {
        window.location.href = '/sign-up/success'
      } else {
        showError('입점신청 중 오류가 발생했습니다: ' + data.message)
      }
    })
    .catch((error) => {
      hideSpinner()
      showError('입점신청 중 오류가 발생했습니다: ' + error.message)
    })
    .finally(() => {
      hideSpinner()
    })
}

function initializeComponents() {
  const fileInput = document.getElementById('cover')
  const submitButton = document.getElementById('submitButton')

  if (fileInput) {
    fileInput.addEventListener('change', handleFileSelection)
  } else {
    console.error('File input element not found')
  }

  if (submitButton) {
    submitButton.addEventListener('click', handleSubmit)
  } else {
    console.error('Submit button not found')
  }

  initializeTagInput()
  initializeFileInput()
  // Hide spinner when page loads (in case it's visible from a previous upload)
  hideSpinner()
}

// DOM이 로드된 후 초기화 함수 실행
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', initializeComponents)
} else {
  initializeComponents()
}

function initializeFileInput() {
  const fileNameInput = document.querySelector('.file-name')
  const fileInput = document.getElementById('cover')

  // .file-name을 클릭하면 파일 선택 대화상자가 열리도록 합니다.
  fileNameInput.addEventListener('click', function () {
    fileInput.click()
  })

  // 파일이 선택되면 .file-name의 값을 업데이트합니다.
  fileInput.addEventListener('change', function () {
    if (this.files && this.files.length > 0) {
      fileNameInput.value = this.files[0].name
    }
  })
}

function convertToWebpExtension(fileName) {
  // 파일 이름에서 마지막 점(.) 위치를 찾습니다.
  const lastDotIndex = fileName.lastIndexOf('.')

  // 확장자가 없거나 파일 이름이 점으로 시작하는 경우, 그냥 .webp를 추가합니다.
  if (lastDotIndex === -1 || lastDotIndex === 0) {
    return `${fileName}.webp`
  }

  // 파일 이름의 확장자 부분을 제외한1 나머지와 .webp를 결합합니다.
  const nameWithoutExtension = fileName.substring(0, lastDotIndex)
  return `${nameWithoutExtension}.webp`
}

export { initializeComponents }
